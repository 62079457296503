import React from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from 'styled-components';
import {AiFillLinkedin} from 'react-icons/ai';
import { Link } from "gatsby";
import {FadeIn} from '../animations/anim';

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  outline: none;
  user-select: none;
  &:visited {
    color: white;
  }
`;

const members = [
  {
    name: 'Sebastian Haglund El Gaidi',
    role: 'CEO and Co-Founder',
    edTitle: 'MSc. in Energy Engineering',
    school: 'KTH, Royal Institute of Technology',
    description: 'Sebastian has working experience from power trading in a large utility company. His is passionate about contributing to fight climate change and is a strong believer that the use of adequate algorithms has the potential of radically speeding up the transition to a sustainable energy system.',
    linkedIn: 'https://www.linkedin.com/in/sebastian-haglund-el-gaidi/',
    image: require('../images/team/sebastian_clipped.jpeg')
  },
  {
    name: 'Mihai Chiru',
    role: 'CTO and Co-Founder',
    edTitle: 'MSc. Distributed Systems',
    school: 'KTH, Royal Institute of Technology',
    description: 'Mihai is a Senior Data Scientist and software engineer with several years of experience as a consultant in the heat and power industry. Mihai has a strong drive to build smart systems that help transform data into real-world decisions. Mihai is a former practitioner of competitive programming and has participated in several national competitions in Romania.',
    linkedIn: 'https://www.linkedin.com/in/mihai-silviu-chiru/',
    image: require('../images/team/mihai_clipped.jpeg')
  },
  {
    name: 'Karl Thunell',
    role: 'CCO',
    edTitle: 'MSc. in Industrial Engineering and Management',
    school: 'KTH, Royal Institute of Technology',
    description: 'Kalle has several years of experience as a Key Account Manager in the eMobility industry in the Nordics. He found interest for the changing energy landscape during his studies and he thrives in the intersection of technical know-how and business sales. He does not leave anything to chance, by bringing structure and creating win-win situations for all parties involved.',
    linkedIn: 'https://www.linkedin.com/in/karl-thunell-241455155/',
    image: require('../images/team/kalle.jpg')
  },
  {
    name: 'Ilias Dimoulkas',
    role: 'Senior Data Scientist',
    edTitle: 'MSc. Energy Production and Management',
    school: 'National Technical University of Athens',
    description: 'Ilias is currently pursuing his PhD in the department of Electric Power and Energy Systems at KTH. His research work involves optimization and forecasting techniques in the power systems. He and his team obtained a second position in the wind power forecasting competition organized by the International Conference on the European Energy Market. ',
    linkedIn: 'https://www.linkedin.com/in/ilias-dimoulkas-6120b2a6/',
    image: require('../images/team/ilias_clipped.jpeg')
  },
  {
    name: 'Henrik Kälvegren',
    role: 'Full stack developer',
    edTitle: 'BSc. Computer Science',
    school: 'KTH, Royal Institute of Technology',
    description: 'Henrik thrives in creating products that are accessible and intuitive to the end-customer. He knows the importance of building a user experience that both solves the users’ every day problems, as well as being easy to grasp and understand.',
    linkedIn: 'https://www.linkedin.com/in/henrik-k%C3%A4lvegren-1129a8147/',
    image: require('../images/team/henrik_clipped.jpeg')
  },
  {
    name: 'Velibor Zeli',
    role: 'Meteorologist',
    edTitle: 'MSc. in Meteorology',
    school: 'University of Novi Sad',
    description: 'Velibor is the team weather expert. He knows a lot about the physical processes governing the dynamics of the atmosphere. Velibor is currently a PhD. student at the Fluid Dynamics department at KTH. His research is in understanding and developing turbulence models for the atmospheric boundary layer. In his time off, Velibor is a dedicated glider pilot.',
    linkedIn: 'https://www.linkedin.com/in/velibor-zeli/',
    image: require('../images/team/velibor_clipped.jpeg')
  },

]

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

const Member = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-name: '${FadeIn}';
  animation-duration: .4s;
  animation-fill-mode: forwards;
  animation-delay: ${props => `${props.animDelay}s`};
  opacity: 0;
`;

const Text = styled.p`
  line-height: 24px;
  text-align: left;
`


const Team = (props) => {



  return (
    <Layout location={props.location}>
      <SEO title="Team" />
      <Grid style={{padding: 48, marginTop: 128}}>
        {members.map((m, i) => {
          return (
            <Member
              key={`${m.name}${m.role}`}
              animDelay={0.12*i}
              >
              <img src={m.image}/>
              <h2>
                {m.name}
              </h2>
              <p>
                {m.role}
              </p>
              <p style={{margin: 0, textAlign: 'center'}}>
                {m.edTitle}
              </p>
              <div style={{display: 'flex', flexDirection: 'column', height: 40}}>
                <p style={{margin: 0, flex: 1}}>
                  {m.school}
                </p>
                <p style={{flex: 1}}>
                  {m.school2}
                </p>
              </div>
              <StyledLink to={m.linkedIn} target='_blank'>
                <AiFillLinkedin
                  size={32} style={{margin: 32}}
                />
              </StyledLink>
              <Text>
                {m.description}
              </Text>
            </Member>
          )
        })}
      </Grid>
    </Layout>
  );

}

export default Team;
